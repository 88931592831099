import '../sass/app.scss';

if (window.jQuery('booking-cancel-form, confirm-survey-form, contact-form, contact-layout, cookiebot-dialog, [v-faq-accordion]').length > 0) {
  import('./apps/cms-front').then((module) => {
    module.default();
  });
}

if (window.jQuery('[pepe-slider-mobile], [pepe-slider-desktop], [pepe-slider-links-mobile]').length > 0) {
  import('./apps/sliders').then((module) => {
    module.default();
  });
}

import('./apps/scripts').then((module) => {
  module.default();
});
